export enum TestFailureType {
    Unknown = 0,
    AssertionFailure = 1,
    PerformanceRegression = 2,
    System = 3,
    ThrownError = 4,
    UncaughtException = 5,
    UnmatchedExpectedFailure = 6,
    ExpectedFailure = 7,
    Aborted = 8,
}
