import { TestRunResult } from "app/_models/test.run.result"
import { Injectable } from "@angular/core"
import { TestFailureType, TestRun } from "app/_models"

@Injectable()
export class TestRunResultFormatter {

    testRunResultDisplayValue(testRun: TestRun): string {
        switch (testRun.result) {
            case TestRunResult.Success: return "Passed"
            case TestRunResult.Failure: return this.testFailureTypeDisplayValue(testRun.failureType)
            case TestRunResult.Skipped: return "Skipped"
            case TestRunResult.Pending: return "Pending"
            case TestRunResult.InProgress: return "In Progress"
            default: return "Unknown Result"
        }
    }

    testRunResultBadgeClass(testRun: TestRun): string {
        switch (testRun.result) {
            case TestRunResult.Success: return "bg-success-400"
            case TestRunResult.Failure:
                if (testRun.failureType == TestFailureType.ExpectedFailure) {
                    return "bg-success-300"
                } else if (testRun.failureType == TestFailureType.Aborted) {
                    return "bg-slate-400"
                } else {
                    return "badge-danger"
                }
            case TestRunResult.Skipped: return "bg-grey-400"
            case TestRunResult.Pending: return "bg-grey-400"
            case TestRunResult.InProgress: return "bg-grey-400"
            default: return "bg-grey-400"
        }
    }

    private testFailureTypeDisplayValue(testFailureType: TestFailureType): string {
        switch (testFailureType) {
            case TestFailureType.Unknown: return "Unknown Failure"
            case TestFailureType.AssertionFailure: return "Assertion Failure"
            case TestFailureType.PerformanceRegression: return "Performance Regression"
            case TestFailureType.System: return "System"
            case TestFailureType.ThrownError: return "Thrown Error"
            case TestFailureType.UncaughtException: return "Uncaught Exception"
            case TestFailureType.UnmatchedExpectedFailure: return "Unmatched Expected Failure"
            case TestFailureType.ExpectedFailure: return "Expected Failure"
            case TestFailureType.Aborted: return "Aborted"
            default: return "Unknown Failure"
        }
    }

}
