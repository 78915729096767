import { Component, Input } from '@angular/core';
import { CallStack, CallStackFrame } from 'app/_models';

@Component({
    selector: 'call-stack',
    templateUrl: 'call.stack.component.html',
    styleUrls: [
        'call.stack.component.css'
    ]
})

export class CallStackComponent {

    _callStack: CallStack
    @Input()
    set callStack(callStack: CallStack) {
        this._callStack = callStack
        this.reloadCallStack()
    }
    get callStack() { return this._callStack }

    _crashedSymbol: string | null
    @Input()
    set crashedSymbol(crashedSymbol: string) {
        this._crashedSymbol = crashedSymbol
        this.reloadCallStack()
    }
    get crashedSymbol() { return this._crashedSymbol }

    frames: CallStackFrame[]
    showImageNameColumn = false
    crashedFrameIndex = -1

    constructor(
    ) { }

    private reloadCallStack() {
        this.frames == null
        this.showImageNameColumn = false
        this.crashedFrameIndex = -1

        if (this.callStack == null) {
            return
        }

        this.callStack.frames.forEach((frame, index) => {
            if (!this.showImageNameColumn && frame.image) {
                this.showImageNameColumn = true
            }

            if (this.crashedFrameIndex < 0 && this.crashedSymbol && frame.symbol == this.crashedSymbol) {
                this.crashedFrameIndex = index
            }
        })

        this.frames = this.callStack.frames
    }

    imageNameForFrame(frame: CallStackFrame): string {
        return frame.image == null ? "???" : frame.image
    }

    copySymbolLocationToClipboard(frame: CallStackFrame) {
        let symbolLocation = this.symbolLocationForFrame(frame)
        if (symbolLocation) {
            navigator.clipboard.writeText(symbolLocation)
        }
    }

    symbolLocationForFrame(frame: CallStackFrame): string | null {
        if (frame.fileName == null) {
            return null
        }

        var description = frame.fileName
        if (frame.lineNumber != null && frame.lineNumber != 0) {
            description += `:${frame.lineNumber}`
        }

        return description
    }

}
