<div class="d-flex align-items-baseline justify-content-between mb-2">
    <div class="d-flex align-items-baseline">
        <h5 class="mb-0 pb-0">Trends</h5>

        <ul class="nav nav-pills nav-pills-bordered ml-2 mb-0">
            <li class="nav-item">
                <a href="javascript:void(0)" (click)="setAppLaunchTrendType(AppLaunchTrendType.Appdex)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : appLaunchTrendType == AppLaunchTrendType.Appdex }">
                    Appdex
                </a>
            </li>

            <li class="nav-item">
                <a href="javascript:void(0)" (click)="setAppLaunchTrendType(AppLaunchTrendType.DurationAvg)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : appLaunchTrendType == AppLaunchTrendType.DurationAvg }" style="border-top-right-radius: 0; border-bottom-right-radius: 0;">
                    <span>Duration<sub>avg</sub></span>
                </a>
            </li>

            <li class="nav-item ml-0">
                <a href="javascript:void(0)" (click)="setAppLaunchTrendType(AppLaunchTrendType.DurationP90)" class="nav-link border-left-0 px-2 py-1" [ngClass]="{ 'active' : appLaunchTrendType == AppLaunchTrendType.DurationP90 }" style="border-radius: 0;">
                    <span>P<sub>90</sub></span>
                </a>
            </li>

            <li class="nav-item ml-0">
                <a href="javascript:void(0)" (click)="setAppLaunchTrendType(AppLaunchTrendType.DurationP95)" class="nav-link border-left-0 px-2 py-1" [ngClass]="{ 'active' : appLaunchTrendType == AppLaunchTrendType.DurationP95 }" style="border-radius: 0;">
                    <span>P<sub>95</sub></span>
                </a>
            </li>

            <li class="nav-item ml-0">
                <a href="javascript:void(0)" (click)="setAppLaunchTrendType(AppLaunchTrendType.DurationP99)" class="nav-link border-left-0 px-2 py-1" [ngClass]="{ 'active' : appLaunchTrendType == AppLaunchTrendType.DurationP99 }" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                    <span>P<sub>99</sub></span>
                </a>
            </li>

            <li class="nav-item">
                <a href="javascript:void(0)" (click)="setAppLaunchTrendType(AppLaunchTrendType.Launches)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : appLaunchTrendType == AppLaunchTrendType.Launches }">
                    Launches
                </a>
            </li>

            <li class="nav-item">
                <a href="javascript:void(0)" (click)="setAppLaunchTrendType(AppLaunchTrendType.OsVersionMajor)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : appLaunchTrendType == AppLaunchTrendType.OsVersionMajor }">
                    OS version
                </a>
            </li>

            <li class="nav-item">
                <a href="javascript:void(0)" (click)="setAppLaunchTrendType(AppLaunchTrendType.AppVersion)" class="nav-link px-2 py-1" [ngClass]="{ 'active' : appLaunchTrendType == AppLaunchTrendType.AppVersion }">
                    App version
                </a>
            </li>
        </ul>
    </div>

    <div class="d-flex" *ngIf="appLaunchTrendsResponse && appLaunchTrendType == AppLaunchTrendType.Appdex">
        <h5 class="mb-0 pb-0"><span class="badge badge-light ml-2">Overall&nbsp;score&nbsp;<appdex [value]="appLaunchTrendsResponse.overallAppdex"></appdex></span></h5>
    </div>

    <div class="d-flex" *ngIf="appLaunchTrendsResponse && appLaunchTrendType == AppLaunchTrendType.DurationAvg">
        <h5><div class="badge badge-light ml-2">Launch duration<sub>avg</sub><milliseconds class="ml-1" [duration]="appLaunchTrendsResponse.overallTotalDuration"></milliseconds></div></h5>
    </div>

    <div class="d-flex" *ngIf="appLaunchTrendsResponse && appLaunchTrendType == AppLaunchTrendType.DurationP90">
        <h5><div class="badge badge-light ml-2">Launch duration<sub>P90</sub><milliseconds class="ml-1" [duration]="appLaunchTrendsResponse.overallTotalDuration"></milliseconds></div></h5>
    </div>

    <div class="d-flex" *ngIf="appLaunchTrendsResponse && appLaunchTrendType == AppLaunchTrendType.DurationP95">
        <h5><div class="badge badge-light ml-2">Launch duration<sub>P95</sub><milliseconds class="ml-1" [duration]="appLaunchTrendsResponse.overallTotalDuration"></milliseconds></div></h5>
    </div>

    <div class="d-flex" *ngIf="appLaunchTrendsResponse && appLaunchTrendType == AppLaunchTrendType.DurationP99">
        <h5><div class="badge badge-light ml-2">Launch duration<sub>P99</sub><milliseconds class="ml-1" [duration]="appLaunchTrendsResponse.overallTotalDuration"></milliseconds></div></h5>
    </div>

    <div class="d-flex" *ngIf="appLaunchTrendsResponse && appLaunchTrendType == AppLaunchTrendType.Launches">
        <h5 class="mb-0 pb-0"><span class="badge badge-light ml-2">Total&nbsp;launches&nbsp;<count [count]="appLaunchTrendsResponse.totalLaunchesCount"></count></span></h5>
        <h5 class="mb-0 pb-0"><span class="badge badge-light ml-2">Unique&nbsp;devices&nbsp;<count [count]="appLaunchTrendsResponse.distinctDeviceIds"></count></span></h5>
    </div>
</div>

<div class="justify-content-center text-muted d-flex w-100 align-items-center" style="min-height: 240px" *ngIf="!appLaunchTrendsResponse">
    <div class="align-self-center">
        <i class="icon-spinner4 spinner mr-2"></i> Loading app launch trends...
    </div>
</div>

<ng-container *ngIf="appLaunchTrendsResponse">
    <div *ngIf="appLaunchTrendType == AppLaunchTrendType.Appdex">
        <appdex-chart [datePoints]="appLaunchTrendsResponse.datePoints" [values]="appLaunchTrendsResponse.appdexes" (onDateRangeChange)="onDateRangeChanged($event)"></appdex-chart>
    </div>

    <div *ngIf="appLaunchTrendType == AppLaunchTrendType.DurationAvg || appLaunchTrendType == AppLaunchTrendType.DurationP90 || appLaunchTrendType == AppLaunchTrendType.DurationP95 || appLaunchTrendType == AppLaunchTrendType.DurationP99">
        <app-launch-duration-chart [datePoints]="appLaunchTrendsResponse.datePoints" [launchDurations]="appLaunchTrendsResponse.totalDurations" (onDateRangeChange)="onDateRangeChanged($event)"></app-launch-duration-chart>
    </div>

    <div *ngIf="appLaunchTrendType == AppLaunchTrendType.Launches">
        <requests-over-time-chart [datePoints]="appLaunchTrendsResponse.datePoints" [requestCounts]="appLaunchTrendsResponse.launchCounts" (onDateRangeChange)="onDateRangeChanged($event)"></requests-over-time-chart>
    </div>

    <div *ngIf="appLaunchTrendType == AppLaunchTrendType.OsVersionMajor">
        <os-launch-rate-chart [datePoints]="appLaunchTrendsResponse.datePoints" [osLaunchRates]="appLaunchTrendsResponse.osLaunchRates" (onDateRangeChange)="onDateRangeChanged($event)"></os-launch-rate-chart>
    </div>

    <div *ngIf="appLaunchTrendType == AppLaunchTrendType.AppVersion">
        <app-version-launch-rate-chart [datePoints]="appLaunchTrendsResponse.datePoints" [appVersionLaunchRates]="appLaunchTrendsResponse.appVersionLaunchRates" (onDateRangeChange)="onDateRangeChanged($event)"></app-version-launch-rate-chart>
    </div>
</ng-container>
