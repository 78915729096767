<div class="content-wrapper" id="pageContent">

    <div class="content">
        <div class="row">
            <div class="col-md-12">
                <alert></alert>
            </div>
        </div>

        <div class="justify-content-center text-muted d-flex w-100 align-items-center" style="height: 90vh" *ngIf="didFinishLoading == false">
            <div class="align-self-center">
                <i class="icon-spinner4 spinner mr-2"></i> Loading test run...
            </div>
        </div>

        <div class="row" [style.visibility]="didFinishLoading ? 'visible' : 'hidden'">
            <div class="col-md-12">
                <div class="card">
                    <div class="page-header border-bottom-light" *ngIf="didFinishLoading">
                        <div class="page-header-content header-elements-md-inline">
                            <div class="page-title">
                                <h1 class="font-weight-thin">
                                    <a [routerLink]="['../../../../']" class="breadcrumb-item">{{application.name}}</a>
                                    <a [routerLink]="['../../../']" class="breadcrumb-item">Test Executions</a>
                                    <a [routerLink]="['../../']" class="breadcrumb-item">#{{testExecution.serialNumber}}</a>
                                    <span class="breadcrumb-item active">
                                        <a href="javascript:void(0)" class="text-default hover-expendable expanded-area breakall" (click)="copyTestNameToClipboard()">
                                            {{testRun.testCase.suiteName}}.{{testRun.testCase.name}} <i class="icon-files-empty hover-target" style="font-size: 70%;"></i>
                                        </a>
                                    </span>
                                </h1>
                                <ul class="list-inline list-inline-dotted list-inline-condensed mb-0">
                                    <li class="list-inline-item font-weight-thin"><span class="badge badge-pill" [class]="testRunResultFormatter.testRunResultBadgeClass(testRun)">{{testRunResultFormatter.testRunResultDisplayValue(testRun)}}</span></li>
                                    <li class="list-inline-item font-weight-thin">{{dateFormatter.dateTime(testRun.startDate)}}</li>
                                    <li class="list-inline-item font-weight-thin" *ngIf="successfulTestRunInfo"><a [routerLink]="['../../../', successfulTestRunInfo.testExecutionSlug, 'runs', successfulTestRunInfo.testRunSlug]" class="font-weight-semibold">Open latest successful run</a></li>
                                </ul>
                            </div>

                            <div class="header-elements" style="flex-wrap: nowrap;">
                                <header-value-item title="ENVIRONMENT" value="{{testExecution.environmentName}} {{testExecution.environmentVersion}}" *ngIf="testExecution.environmentName"></header-value-item>
                                <header-value-item title="APP VERSION" value="{{testExecution.testBundleVersion}}" *ngIf="testExecution.testBundleVersion"></header-value-item>
                                <header-value-item title="RUN TIME" value="{{testRunDuration}}"></header-value-item>
                            </div>
                        </div>
                    </div>

                    <div class="card-body d-flex" *ngIf="didFinishLoading && showTestRunDetail == false">
                        {{testResultStatus()}}
                    </div>

                    <div class="card-body d-flex" *ngIf="testRun && showTestRunDetail && didFinishLoading">
                        <image-video #imageVideo [imageFile]="testRun.screenshot" [videoFile]="testRun.screenRecording" (progressChange)="onVideoProgressChange($event)" class="mr-3" *ngIf="testRun.screenshot"></image-video>
                        <div class="w-100">
                            <div class="row pb-2 mb-2 border-bottom" *ngIf="testRun.failureDescription">
                                <div class="col-sm-12">
                                    <h5 class="card-title mb-0 breakall">{{testRun.failureDescription}}</h5>
                                    <ul class="list-inline list-inline-dotted list-inline-condensed mb-0 align-items-baseline">
                                        <li class="list-inline-item" *ngIf="testRun.failureFilePath">
                                            <span class="text-muted">{{testRun.failureFilePath}}</span>
                                            <span class="text-muted" *ngIf="testRun.failureLineNumber">:{{testRun.failureLineNumber}}</span>
                                        </li>
                                        <li class="list-inline-item" *ngIf="testCallStackFile">
                                            <a href="javascript:void(0)" (click)="onShowTestCallStackClick()" class="font-weight-semibold">Test stack trace</a>
                                        </li>
                                        <li class="list-inline-item" *ngIf="appCrashCallStackFile">
                                            <a href="javascript:void(0)" (click)="onShowAppCrashCallStackClick()" class="font-weight-semibold">App crash stack trace</a>
                                        </li>
                                    </ul>

                                    <call-stack id="call_stack" [callStack]="selectedCallStack" *ngIf="selectedCallStack"></call-stack>
                                </div>
                            </div>

                            <div class="row pb-2 mb-2 border-bottom">
                                <div class="col-sm-12">
                                    <h6 class="font-weight-semibold mb-1">
                                        <a [routerLink]="['../../../../test-cases', testRun.testCase.serialNumber]" class="button-muted breakall">{{testRun.testCase.suiteName}}.{{testRun.testCase.name}}</a>
                                    </h6>
                                    <div *ngIf="testRun.testCase.annotation">
                                        <markdown [content]="testRun.testCase.annotation"></markdown>
                                        <ul class="list-inline list-inline-dotted list-inline-condensed mt-1 mb-0">
                                            <li class="list-inline-item"><a href="javascript:void(0)" (click)="onAnnotateTestCaseButtonClick()" class="font-weight-semibold button-muted" data-toggle="modal" data-target="#editTestCaseModal">Edit annotation</a></li>
                                            <li class="list-inline-item"><span class="text-muted font-size-sm font-weight-light">Updated {{dateFormatter.timeAgo(testRun.testCase.annotationUpdateDate)}}</span></li>
                                        </ul>
                                    </div>
                                    <div *ngIf="testRun.testCase.annotation == null">
                                        <a href="javascript:void(0)" (click)="onAnnotateTestCaseButtonClick()" class="font-weight-semibold" data-toggle="modal" data-target="#editTestCaseModal">Annotate this test case</a>
                                        <span class="text-muted"> by attaching more information and references.</span>
                                    </div>
                                </div>
                            </div>

                            <div class="row pb-2 mb-2 border-bottom" *ngIf="testFailureGroup">
                                <div class="col-md-7 col-sm-7">
                                    <h6 class="font-weight-semibold mb-1">
                                        <a [routerLink]="['../../../../test-failure-groups', testFailureGroup.serialNumber]" class="button-muted">Failure group #{{testFailureGroup.serialNumber}}</a>
                                    </h6>
                                    <div *ngIf="testFailureGroup.annotation">
                                        <markdown [content]="testFailureGroup.annotation"></markdown>
                                        <ul class="list-inline list-inline-dotted list-inline-condensed mt-1 mb-0">
                                            <li class="list-inline-item"><a href="javascript:void(0)" (click)="onAnnotateTestFailureGroupButtonClick()" class="font-weight-semibold button-muted" data-toggle="modal" data-target="#editTestFailureGroupModal">Edit annotation</a></li>
                                            <li class="list-inline-item"><span class="text-muted font-size-sm font-weight-light">Updated {{dateFormatter.timeAgo(testFailureGroup.annotationUpdateDate)}}</span></li>
                                        </ul>
                                    </div>
                                    <div *ngIf="testFailureGroup.annotation == null">
                                        <a href="javascript:void(0)" (click)="onAnnotateTestFailureGroupButtonClick()" class="font-weight-semibold" data-toggle="modal" data-target="#editTestFailureGroupModal">Annotate this failure</a>
                                        <span class="text-muted"> by attaching more information and references.</span>
                                    </div>
                                </div>
                                <div class="col-md-5 col-sm-12 pr-0" *ngIf="testFailureGroupWidget.currentPeriodCount > 0">
                                    <div class="card m-0 noshadow border-light h-100">
                                        <widget-view class="h-100" [widgetView]="testFailureGroupWidget"></widget-view>
                                    </div>
                                </div>
                            </div>

                            <!-- Only add bottom border for successful test runs which don't have attachments -->
                            <div class="row" [class]="testRun.failureDescription ? 'border-bottom pb-2 mb-2' : ''">
                                <div class="col-sm-12">
                                    <h6 class="font-weight-semibold mb-1">Details</h6>
                                    <div class="row">
                                        <value-item class="col-lg-6 col-xl-3" title="Current view" value="{{testRun.currentView}}" icon="icon-stack4" *ngIf="testRun.currentView"></value-item>
                                        <value-item class="col-lg-6 col-xl-3" title="Current view" value="-" icon="icon-stack4" *ngIf="testRun.currentView == null"></value-item>
                                        <value-item class="col-lg-6 col-xl-3" title="Device" value="{{testRun.testExecutor.deviceModelName}}" icon="icon-mobile2"></value-item>
                                        <value-item class="col-lg-6 col-xl-3" title="Screen size" value="{{testRun.testExecutor.screenSize}}" icon="icon-enlarge"></value-item>
                                        <value-item class="col-lg-6 col-xl-3" title="Screen density" value="{{testRun.testExecutor.screenDensity}}" icon="icon-grid"></value-item>
                                        <value-item class="col-lg-6 col-xl-3" title="OS" value="{{testRun.testExecutor.system}}" icon="{{platformFormatter.platformIconName(application.platform)}}"></value-item>
                                        <value-item class="col-lg-6 col-xl-3" title="Locale" value="{{testRun.locale}}" icon="icon-earth"></value-item>
                                    </div>
                                </div>
                            </div>

                            <div class="row pb-2" *ngIf="testRun.screenshot || testRun.accessibilitySnapshot">
                                <div class="col-sm-12">
                                    <h6 class="font-weight-semibold mb-0">Attachments</h6>

                                    <ul class="list-inline mb-0">
                                        <!-- Screenshot attachment -->
                                        <li class="list-inline-item mt-2" *ngIf="testRun.screenshot && testRun.screenshot.url">
                                            <div class="media">
                                                <div class="mr-2 align-self-center">
                                                    <i class="icon-file-picture icon-2x text-slate-400 top-0"></i>
                                                </div>

                                                <div class="media-body">
                                                    <a download="{{testRun.testCase.suiteName}}.{{testRun.testCase.name}}_{{testExecution.serialNumber}}.jpeg" href="{{testRun.screenshot.url}}" class="font-weight-semibold">Screenshot.jpeg</a>
                                                    <ul class="list-inline list-inline-dotted list-inline-condensed font-size-sm text-muted">
                                                        <li class="list-inline-item">Size: {{byteCountFormatter.stringFromByteCount(testRun.screenshot.fileSize)}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>

                                        <!-- Screen recording attachment -->
                                        <li class="list-inline-item mt-2" *ngIf="testRun.screenRecording">
                                            <div class="media">
                                                <div class="mr-2 align-self-center">
                                                    <i class="icon-file-play icon-2x text-slate-400 top-0"></i>
                                                </div>

                                                <div class="media-body">
                                                    <a download="{{testRun.testCase.suiteName}}.{{testRun.testCase.name}}_{{testExecution.serialNumber}}.mp4" href="{{testRun.screenRecording.url}}" class="font-weight-semibold">Screen recording.mp4</a>
                                                    <ul class="list-inline list-inline-dotted list-inline-condensed font-size-sm text-muted">
                                                        <li class="list-inline-item">Duration: {{dateFormatter.duration(testRun.screenRecording.duration)}}</li>
                                                        <li class="list-inline-item">Size: {{byteCountFormatter.stringFromByteCount(testRun.screenRecording.fileSize)}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>

                                        <!-- Accessibility snapshot attachment -->
                                        <li class="list-inline-item mt-2" *ngIf="testRun.accessibilitySnapshot">
                                            <div class="media">
                                                <div class="mr-2 align-self-center">
                                                    <i class="icon-file-text2 icon-2x text-slate-400 top-0"></i>
                                                </div>

                                                <div class="media-body">
                                                    <a download="{{testRun.testCase.suiteName}}.{{testRun.testCase.name}}_{{testExecution.serialNumber}}.txt" href="{{testRun.accessibilitySnapshot.url}}" class="font-weight-semibold">Accessibility snapshot.txt</a>
                                                    <ul class="list-inline list-inline-dotted list-inline-condensed font-size-sm text-muted">
                                                        <li class="list-inline-item">Size: {{byteCountFormatter.stringFromByteCount(testRun.accessibilitySnapshot.fileSize)}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="border-top" *ngIf="showTestRunDetail && testRun && testRun.unifiedLogs">
                        <application-logs #applicationLogs
                            [logFile]="testRun.unifiedLogs"
                            [videoFile]="testRun.screenRecording"
                            [videoTimelineFile]="testRun.screenRecordingTimeline"
                            (timelinePositionChanged)="onTimelinePositionChanged($event)"
                            (analyzeRequestHandler)="onAnalyzeRequest($event)">
                        </application-logs>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<edit-test-failure-group-modal #editTestFailureGroupModal (testFailureGroupUpdated)="onTestFailureGroupUpdated()"></edit-test-failure-group-modal>
<edit-test-case-modal #editTestCaseModal (testCaseUpdated)="onTestCaseUpdated()"></edit-test-case-modal>
