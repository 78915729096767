import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TestRun, LogFile, TestExecution, Application, TestFailureGroup, CallStack, WidgetView, TestFailureType } from 'app/_models';
import * as moment from 'moment'
import { AlertService, TestRunsService, TestExecutionsService, TestFailureGroupsService, ApplicationService, AuthenticationService, NetworkOperationsService, TestCasesService, SuccessfulTestRunInfo } from 'app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationFormatter, ByteCountFormatter, DateFormatter, PlatformFormatter, TestRunResultFormatter } from 'app/_helpers';
import { TestRunResult } from 'app/_models/test.run.result';
import { EditTestFailureGroupModal } from './edit.test.failure.group.modal/edit.test.failure.group.modal';
import { EditTestCaseModal } from './edit.test.case.modal/edit.test.case.modal';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ApplicationLogsComponent } from 'app/_components';
import { ImageVideoComponent } from 'app/_components/image.video.component/image.video.component';
import { Title } from '@angular/platform-browser';
import { NetworkResponseLog } from 'app/_models/console.logs.response';

@Component({
    selector: 'test-run',
    templateUrl: 'test.run.component.html'
})
export class TestRunComponent implements OnInit, OnDestroy {

    application: Application

    @ViewChild('editTestFailureGroupModal') editTestFailureGroupModal: EditTestFailureGroupModal
    @ViewChild('editTestCaseModal') editTestCaseModal: EditTestCaseModal
    @ViewChild('applicationLogs') applicationLogs: ApplicationLogsComponent
    @ViewChild('imageVideo') imageVideo: ImageVideoComponent

    testExecution: TestExecution
    testRun: TestRun
    testFailureGroup: TestFailureGroup | null
    testFailureGroupWidget: WidgetView | null

    // Separate property for binding
    testCallStackFile: LogFile
    appCrashCallStackFile: LogFile
    testCallStack: CallStack
    appCrashCallStack: CallStack
    selectedCallStack: CallStack
    testRunDuration: string
    showTestRunDetail = false
    didFinishLoading = false
    successfulTestRunInfo: SuccessfulTestRunInfo

    private routeParamsSubscription: Subscription
    private currentApplicationSubscription: Subscription

    constructor(
        public dateFormatter: DateFormatter,
        public byteCountFormatter: ByteCountFormatter,
        public testRunResultFormatter: TestRunResultFormatter,
        public platformFormatter: PlatformFormatter,
        private alertService: AlertService,
        private testRunService: TestRunsService,
        private http: HttpClient,
        private route: ActivatedRoute,
        private applicationService: ApplicationService,
        private testCasesService: TestCasesService,
        private testExecutionService: TestExecutionsService,
        private testFailureGroupsService: TestFailureGroupsService,
        private applicationFormatter: ApplicationFormatter,
        private router: Router,
        private authenticationService: AuthenticationService,
        private networkOperationsService: NetworkOperationsService,
        private titleService: Title
    ) {
    }

    ngOnInit() {
        this.currentApplicationSubscription = this.applicationService.currentApplication.subscribe((application) => {
            this.application = application
            if (this.application != null && this.routeParamsSubscription == null) {
                this.routeParamsSubscription = this.route.params.subscribe(params => {
                    this.reloadData()
                })
            }
        })
    }

    ngOnDestroy() {
        this.currentApplicationSubscription?.unsubscribe()
        this.routeParamsSubscription?.unsubscribe()
    }

    onShowTestCallStackClick() {
        if (this.selectedCallStack != null && this.selectedCallStack == this.testCallStack) {
            this.selectedCallStack = null
            return
        }

        if (this.testCallStack != null) {
            this.selectedCallStack = this.testCallStack
            return
        }

        this.http.get(this.testCallStackFile.url, { responseType: 'text' }).subscribe(
            response => {
                let responseObject = JSON.parse(response)
                if (responseObject['version'] != 1) {
                    return
                }
                this.testCallStack = responseObject
                this.selectedCallStack = this.testCallStack

            }, error => {
                this.alertService.handleError(error)
            }
        )
    }

    onShowAppCrashCallStackClick() {
        if (this.selectedCallStack != null && this.selectedCallStack == this.appCrashCallStack) {
            this.selectedCallStack = null
            return
        }

        if (this.appCrashCallStack != null) {
            this.selectedCallStack = this.appCrashCallStack
            return
        }

        this.http.get(this.appCrashCallStackFile.url, { responseType: 'text' }).subscribe(
            response => {
                let responseObject = JSON.parse(response)
                if (responseObject['version'] != 1) {
                    return
                }
                this.appCrashCallStack = responseObject
                this.selectedCallStack = this.appCrashCallStack

            }, error => {
                this.alertService.handleError(error)
            }
        )
    }

    onAnnotateTestCaseButtonClick() {
        this.editTestCaseModal.testCase = this.testRun.testCase
    }

    onAnnotateTestFailureGroupButtonClick() {
        this.editTestFailureGroupModal.testFailureGroup = this.testFailureGroup
    }

    onTestFailureGroupUpdated() {
        this.reloadTestFailureGroup()
    }

    onTestCaseUpdated() {
        this.reloadTestRun()
    }

    testResultStatus(): string {
        switch (this.testRun.result) {
            case TestRunResult.Pending: return "Test is scheduled to be executed."
            case TestRunResult.InProgress: return "Test is currently executing."
            case TestRunResult.Skipped: return "Test skipped."
            case TestRunResult.Success: return "Test succeeded."
            case TestRunResult.Failure:
                if (this.testRun.failureType == TestFailureType.ExpectedFailure) {
                    return "Expected failure."
                } else if (this.testRun.failureType == TestFailureType.Aborted) {
                    return "Aborted."
                } else {
                    return ""
                }
        }
    }

    copyTestNameToClipboard() {
        navigator.clipboard.writeText(`${this.testRun.testCase.suiteName}.${this.testRun.testCase.name}`)
    }

    onVideoProgressChange(progress: number) {
        this.applicationLogs.setTimelinePosition(progress, false)
    }

    onTimelinePositionChanged(position: number) {
        this.imageVideo.setVideoPosition(position)
    }

    onAnalyzeRequest(responseLog: NetworkResponseLog) {
        if (!this.testExecution.environmentName) { return }

        this.networkOperationsService.resolveNetworkRequest(this.authenticationService.currentWorkspaceValue.id, responseLog.method, responseLog.url.toString()).then((response) => {
            this.router.navigate(
                ['/', this.authenticationService.currentWorkspaceValue.id, 'envs', this.testExecution.environmentSlug, 'network'],
                {
                    queryParams: {
                        // environment: this.testExecution.environmentName,
                        networkOperation: response.networkOperationId,
                        history: '1D'
                    },
                    queryParamsHandling: 'merge'
                }
            )

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadData() {
        this.didFinishLoading = false

        if (!this.application) { return }

        let testExecutionSlug = this.route.snapshot.params['testExecutionSlug']

        // If the TestExecution is already loaded and matches the route param, no need to reload it
        if (this.testExecution && this.testExecution.serialNumber == testExecutionSlug) {
            this.reloadTestRun()
            return
        }

        return this.testExecutionService.getApplicationTestExecutionWithSlug(this.application.id, testExecutionSlug).then((response) => {
            this.testExecution = response.data
            this.reloadTestRun()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadTestRun() {
        if (this.testExecution == null) { return }

        let testRunSlug = this.route.snapshot.params['testRunSlug']

        this.testRunService.getTestRunsWithSlug(this.testExecution.id, testRunSlug).then((response) => {
            this.testRun = response.data[0]
            this.showTestRunDetail = (this.testRun.result == TestRunResult.Failure || this.testRun.result == TestRunResult.Success)
            this.testCallStackFile = this.testRun != null ? this.testRun.testCallStack : null
            this.appCrashCallStackFile = this.testRun != null ? this.testRun.appCrashCallStack : null

            let duration = moment(this.testRun.endDate).diff(this.testRun.startDate) / 1000
            this.testRunDuration = this.dateFormatter.duration(duration)

            this.titleService.setTitle(`${this.applicationFormatter.displayName(this.application)} | Test Execution #${this.testExecution.serialNumber} | ${this.testRun.testCase.suiteName}.${this.testRun.testCase.name}`)

            this.reloadTestFailureGroup()
            this.loadLatestSuccessfulTestRunInfoIfNeeded()

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private loadLatestSuccessfulTestRunInfoIfNeeded() {
        this.successfulTestRunInfo = null
        let isFailure = this.testRun.result == TestRunResult.Failure
        if (this.testRun == null || !isFailure) { return }

        this.testCasesService.getLatestSuccessfulTestRunInfo(this.testRun.testCase.id).then((result) => {
            if (result && result.testExecutionSlug != null && result.testRunSlug != null) {
                this.successfulTestRunInfo = result
            }
        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

    private reloadTestFailureGroup() {
        if (!this.testRun || !this.testRun.testFailureGroupId) {
            this.testFailureGroup = null
            this.testFailureGroupWidget = null
            this.didFinishLoading = true
            return
        }

        let failureGroupPromise = this.testFailureGroupsService.getTestFailureGroupById(this.testRun.testFailureGroupId)
        let failureGroupWidgetPromise = this.testFailureGroupsService.getTestFailureGroupWidget(this.testRun.testFailureGroupId)

        return Promise.all([failureGroupPromise, failureGroupWidgetPromise]).then((response) => {
            this.testFailureGroup = response[0].data
            this.testFailureGroupWidget = response[1]
            this.didFinishLoading = true

        }).catch((error) => {
            this.alertService.handleError(error)
        })
    }

}
