<div class="w-100 font-size-sm mt-2" *ngIf="callStack && frames">
    <table>
        <thead>
            <td></td>
            <td *ngIf="showImageNameColumn"></td>
            <td class="w-100"></td>
        </thead>
        <tbody>
            <tr *ngFor="let frame of frames; let i = index" [ngClass]="{ 'crashedFrame' : (i == crashedFrameIndex) }">
                <td>
                    <samp class="text-muted ml-1 mr-2">{{i}}</samp>
                </td>
                <td class="nowrap" *ngIf="showImageNameColumn">
                    <samp [ngClass]="{ 'mr-3': true, 'text-muted': (frame.symbol == null || frame.isSystemImage === true || i < crashedFrameIndex) }">{{imageNameForFrame(frame)}}</samp>
                </td>
                <td>
                    <samp class="breakall">
                        <span class="symbol" [ngClass]="{ 'text-muted': i < crashedFrameIndex }" *ngIf="frame.symbol">{{frame.symbol}}</span>
                        <a href="javascript:void(0)" class="text-default hover-expendable white-space-nowrap" (click)="copySymbolLocationToClipboard(frame)" *ngIf="symbolLocationForFrame(frame)">
                            <span class="text-muted"> {{symbolLocationForFrame(frame)}}</span><i class="icon-files-empty ml-1 hover-target" style="font-size: 85%;"></i>
                        </a>
                    </samp>
                </td>
            </tr>
        </tbody>
    </table>
</div>
